import { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { type Settings as SliderSettings } from 'react-slick';
import { Waypoint } from 'react-waypoint';
import useSWR, { type KeyedMutator } from 'swr';

import {
  ErrorMessage,
  useGameLikeEventEmitter,
  useTriggerManagePinnedGamePacksModal,
} from '../../components/Game/GameCenter';
import {
  DefaultGamePackCardBadges,
  GamePackCard,
  LoadingGamePackCard,
} from '../../components/Game/GamePack/GamePackCard';
import { DoubleRightArrow } from '../../components/icons/Arrows';
import { apiService } from '../../services/api-service';
import {
  type Tag,
  TaggedObjectType,
  tagMatched,
  TagUtils,
  virtualTags,
} from '../../types';
import { type GamePack } from '../../types/game';
import { useGamePackSliderSettings, useNumPerRow } from './Context';
import { GamePackCardBottomAccessory } from './GamePackCardBottomAccessory';

export function getGamePacksByTagId(
  tagId: number,
  personalized: boolean
): Promise<GamePack[]> {
  switch (tagId) {
    case virtualTags.untagged.id:
      return apiService.gamePack.getUntaggedPrimeGamePacks().next();
    case virtualTags.playAgain.id:
      return apiService.gamePack.getPlayedGamePacks().next();
    default:
      return apiService.gamePack
        .getGamePacksByTagId(tagId, personalized)
        .next();
  }
}

function GamePackRowLoading(props: {
  tag: Tag;
  sliderSettings: SliderSettings;
}) {
  const { tag, sliderSettings } = props;
  const numPerRow = useNumPerRow();

  const count = useMemo(() => {
    return Math.min(
      TagUtils.getObjectsCount(tag, TaggedObjectType.PrimeGamePack),
      numPerRow
    );
  }, [numPerRow, tag]);

  return (
    <Slider {...sliderSettings} className='w-full'>
      {[...Array(count)].map((_, i) => (
        <div className='pt-5 px-2' key={i}>
          <LoadingGamePackCard styles={{ size: 'w-full h-[283px]' }} />
        </div>
      ))}
    </Slider>
  );
}

export function useSubscribeGamePackEventEmitter(
  tag: Tag,
  mutate: KeyedMutator<GamePack[]>
) {
  const emitter = useGameLikeEventEmitter('gamePack');

  useEffect(() => {
    const handle = (pack: GamePack) => {
      if (pack.isPrime && tagMatched(pack, tag.id)) {
        mutate();
      }
    };

    const ac = new AbortController();
    const { signal } = ac;
    emitter.on('created', handle, { signal });
    emitter.on('updated', handle, { signal });
    emitter.on('duplicated', handle, { signal });
    emitter.on('published', handle, { signal });
    emitter.on('deleted', handle, { signal });
    return () => ac.abort();
  }, [emitter, tag.id, mutate]);
}

export function GamePackRow(props: {
  tag: Tag;
  personalized: boolean;
  showVersion?: boolean;
  showReorderButton?: boolean;
  showHeader?: boolean;
  onViewAll: (tag: Tag) => void;
  onClickGamePack: (pack: GamePack, index: number) => void;
  onGamePackVisible?: (pack: GamePack, index: number) => void;
}) {
  const {
    tag,
    personalized,
    showVersion,
    showReorderButton,
    showHeader = true,
    onViewAll,
    onClickGamePack,
    onGamePackVisible,
  } = props;

  const [entered, setEntered] = useState(false);

  const {
    data: packs,
    error,
    mutate,
  } = useSWR(
    entered ? [tag.id, personalized] : null,
    async () => {
      return getGamePacksByTagId(tag.id, personalized);
    },
    {
      revalidateOnFocus: false,
    }
  );
  useSubscribeGamePackEventEmitter(tag, mutate);

  const sliderSettings = useGamePackSliderSettings();
  const managePinnedGamePacks = useTriggerManagePinnedGamePacksModal();

  return (
    <div className='w-full flex flex-col text-white'>
      <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll></Waypoint>

      {showHeader && (
        <header className={`mb-6 flex items-center gap-8 group`}>
          <button
            type='button'
            onClick={() => onViewAll(tag)}
            className='btn flex items-center gap-8'
          >
            <p className={`text-2xl font-bold`}>{tag.name}</p>
            <div className='flex items-center gap-2 opacity-0 group-hover:opacity-100'>
              <p>View All</p> <DoubleRightArrow />
            </div>
          </button>
          {showReorderButton && (
            <button
              type='button'
              className='btn-secondary w-20 h-6 opacity-0 group-hover:opacity-100 text-sms rounded-md'
              onClick={() => {
                managePinnedGamePacks({
                  tag: tag,
                  onComplete: () => mutate(),
                });
              }}
            >
              Reorder
            </button>
          )}
        </header>
      )}
      {/* 
        Note(guoqiang): the height must be fixed, given that game packs are loaded asynchronously, 
        which ensure the #hash to jump to the right position. Feel free to increase the height
        if you update the game pack card styles.
      */}
      <main className='w-full h-76 flex justify-center items-center'>
        {packs && packs.length === 0 && (
          <ErrorMessage
            text={`No game packs found`}
            handleRetry={() => mutate}
          />
        )}
        {packs && packs.length > 0 && (
          <Slider
            {...sliderSettings}
            afterChange={
              // This is a hack to make sure the waypoint is triggered when the slider is scrolled.
              // it should not affect performance significantly, as the volume of clicks is not high.
              onGamePackVisible
                ? () => window.dispatchEvent(new Event('scroll'))
                : undefined
            }
            className='w-full'
          >
            {packs?.map((p, index) => (
              <div className='pt-5 px-2' key={p.id}>
                <GamePackCard
                  gamePack={p}
                  onClick={() => onClickGamePack(p, index)}
                  onVisible={
                    onGamePackVisible
                      ? () => onGamePackVisible(p, index)
                      : undefined
                  }
                  badges={<DefaultGamePackCardBadges gamePack={p} />}
                  bottomAccessory={<GamePackCardBottomAccessory pack={p} />}
                  styles={{
                    size: 'w-full',
                  }}
                  showVersion={showVersion}
                />
              </div>
            ))}
          </Slider>
        )}
        {!packs && (
          <GamePackRowLoading tag={tag} sliderSettings={sliderSettings} />
        )}
        {error && (
          <ErrorMessage
            text='Something went wrong'
            handleRetry={() => mutate()}
          />
        )}
      </main>
    </div>
  );
}
